import React, { Component } from 'react'
import Container from '../organisms/PageContainer'
import ContactForm from '../organisms/ContactForm';

export default class Contact extends Component {
    render() {
        return (
            <Container>
                <div className="m-hero --contact">
                    <h1>Holler @ME</h1>
                    <h3>If you would like to discuss a project, get a quote, need a consult on your site, discuss how The Mandalorian blows the prequals and recent trilogy out of the water, etc...</h3>
                </div>
                <section className="m-contact-form__wrapper">
                    <h3 className="a-section-header">Contact Me</h3>
                    <ContactForm />
                </section>
            </Container>
        )
    }
}
