import React, { Component } from 'react'

export default class ContactForm extends Component {
    render() {
        return (
            <form className="m-contact-form" method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact">
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="contact" />
                <label htmlFor="name">Name
                <input type="text" name="name" placeholder="Tommy Tutone"/>
                </label>
                <label htmlFor="Phone">Phone
                <input type="tel" name="phone" placeholder="000-867-5309" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"/>
                </label>
                <label htmlFor="email">Email
                <input type="email" placeholder="songs@StuckInYourHead.com"/>
                </label>
                <label htmlFor="budget">budget
                <input type="number" placeholder="$100 - $1 zillion" /></label>
                <label className="full" htmlFor="how">How can I help?
                <textarea name="how" id="how" placeholder="I can't get the song 867-5309 out of my head"></textarea></label>
                <button className="a-link a-link--contact" type="submit">Submit</button>
            </form>
        )
    }
}
